@charset "utf-8";


@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-Thin.woff') format('woff');
    font-weight: 100;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../font/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-display: swap;
}



/* -----------------------------------------------------------------------------------------------
/////////////////////////////////////////////	reset css  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
* { box-sizing:border-box; }
html, body { height:100%; padding:0; margin:0;  }
html { font-size:10px; letter-spacing:0px; font-family:'Pretendard','Apple SD Gothic Neo', sans-serif; color:#010101; font-weight:300; }
body {min-width:1200px; word-break: keep-all; overflow-x:hidden; margin:0 auto; font-size:1.6rem; }
body,div,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,th,td,form,fieldset,legend,input,textarea,a,button,select
{margin:0;padding:0;box-sizing:border-box; word-break:keep-all;}
body,input,textarea,select,button,table,label,span
{-webkit-text-size-adjust:none;-moz-text-size-adjust:none;-ms-text-size-adjust:none; text-size-adjust:none; font-family:'Pretendard','Apple SD Gothic Neo', sans-serif; }
a {text-decoration:none; color:inherit; cursor:pointer;}
a:link {text-decoration:none; color:inherit;}
a:visited {text-decoration:none; color:inherit;}
a:active {text-decoration:none; color:inherit;}
a:hover {text-decoration:none; color:inherit;}
span, a, em, button {display:inline-block;}
img,fieldset,iframe{border:0;}
img {max-width:100%;}
ul, li{list-style:none;}
caption,
legend {overflow:hidden;position:absolute;top:0;left:-9999px;width:0;height:0;font-size:0;line-height:0;text-align:left;}



/* -----------------------------------------------------------------------------------------------
/////////////////////////////////////////////	공통  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
/* 색상 */
:root {
	--navy : #273053;
	--blue : #20358c;
    --blue01 : #007dc3;
	--black : #010101; 
    --grey : #555;
    --grey01 : #999;
    --grey02 : #a9a9a9;
    --ltgrey : #e9eaed;
    --ltgrey01 : #d4d4d4;
    --ltgrey02 : #f4f4f4;
    --red : #ed1b2f;
    --blueGra: linear-gradient(to right, #20358c 0%, #1e499b 0%, #007dc3 100%); 
    --blueGra01: linear-gradient(to left, #20358c 0%, #1e499b 0%, #007dc3 100%);
}

.hidden { position:absolute; font-size:0; line-height:0; left:-10000px; padding:0 !important; margin:0; height:0;}

/* ios */
a[x-apple-data-detectors] {
  color:inherit !important;
  text-decoration:none !important;
  font-size:inherit !important;
  font-family:inherit !important;
  font-weight:inherit !important;
  line-height:inherit !important;
  white-space:nowrap;
}

.mt0 { margin-top:0rem !important; }
.mt5 { margin-top:0.5rem !important; }
.mt10 { margin-top:1rem !important; }
.mt20 { margin-top:2rem !important; }
.mt30 { margin-top:3rem !important; }
.mt40 { margin-top:4rem !important; }
.mt50 { margin-top:5rem !important; }
.mt60 { margin-top:6rem !important; }
.mt70 { margin-top:7rem !important; }
.mt80 { margin-top:8rem !important; }
.mt90 { margin-top:9rem !important; }
.mt100 { margin-top:10rem !important; }
.mb0 { margin-bottom:0rem !important; }

/* 폰트 */
.blueText { color:var(--blue) !important; }

/*자동완성일때 배경 색상 문제*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;
}

/* 말줄임 */
.line1-text-eps { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; -o-text-overflow: ellipsis; display:block; }
.line2-text-eps { display:block; line-height:1.5em; max-height:3em; overflow:hidden; text-overflow:ellipsis; -o-text-overflow:ellipsis;-webkit-box-orient:vertical; word-wrap:break-word; }
.line3-text-eps { display:block; line-height:1.5em; max-height:4.5em; overflow:hidden; text-overflow:ellipsis; -o-text-overflow:ellipsis;-webkit-box-orient:vertical; word-wrap:break-word; }
.line4-text-eps { display:block; line-height:1.5em; max-height:6em; overflow:hidden; text-overflow:ellipsis; -o-text-overflow:ellipsis;-webkit-box-orient:vertical; word-wrap:break-word; }

/* 리스트 */
ul.list > li { padding-left:1.0rem; position:relative; }
ul.list > li:before { content:''; width:3px; height:3px; border-radius:50%; background:#2b2b2b; display:block; position:absolute; left:0; top:1.5rem; }
ul.list > li + li { margin-top:1.2rem; }

/* 테이블 */
table { border-collapse:collapse; width:100%; }
table.table th { background:#f4f4f4; font-size:16px; color:var(--black); border-top:1px solid #ccc; border-bottom:1px solid #ccc; padding:15px; }
table.table td { background:#fff; font-size:16px; color:var(--grey); border-bottom:1px solid #ddd; padding:15px;  }
table.formTable th,
table.formTable td { font-size:16px; border:1px solid #ccc; padding:15px; }
table.formTable th { background:#f4f4f4; color:var(--black); }
table.formTable.textCenter { text-align:center; }

/* 버튼 */
button { border:0; cursor:pointer; }
.button { display:inline-block; text-align:center; padding:0 15px; height:46px; line-height:46px; font-size:16px; color:var(--black); background:var(--ltgrey01); border-radius:5px; 
    cursor:pointer; border:0; }
.button span { display:flex; align-items:center; gap:2px; justify-content:center; }

.btnBig { padding:0 30px; height:64px; line-height:64px; font-size:20px; color:#fff !important; background:var(--blueGra); border-radius:32px; cursor:pointer; width:220px; }
.btnBig.btnArw span { display:flex; align-items:center; justify-content:center; }
.btnBig.btnArw span:after { content:''; width:22px; height:16px; background:url(../images/btnArw.png)center no-repeat; display:block; margin-left:10px; }
.btnBig.cancleBtn { background:var(--grey); color:#fff; }
.btnSmall { padding:0 10px; height:40px; line-height:40px; font-size:15px; color:var(--black); background:var(--ltgrey); border-radius:5px; cursor:pointer; border:0; padding:0 15px; }

.writeBtn span:before { content:''; width:24px; height:24px; background:url(../images/ico_write.png)center no-repeat; display:block; } /*정보입력*/
.uploadBtn span:before { content:''; width:24px; height:24px; background:url(../images/ico_fileUp.png)center no-repeat; display:block; } /*업로드*/
.downBtn span:before { content:''; width:24px; height:24px; background:url(../images/ico_fileDown.png)center no-repeat; display:block; } /*다운로드*/
.accessListBtn span:before { content:''; width:24px; height:24px; background:url(../images/ico_fileList.png)center no-repeat; display:block; } /*접속이력*/
.installBtn span:before { content:''; width:24px; height:24px; background:url(../images/ico_install.png)center no-repeat; display:block; } /*아이디접속*/

.navyBtn { background:var(--navy); color:#fff !important; }
.blueBtn { background:var(--blue); color:#fff !important; }
.greyBtn { background:var(--grey); color:#fff !important; }

/* -----------------------------------------------------------------------------------------------
/////////////////////////////////////////////	layout  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.inner { width:100%; max-width:1240px; margin:0 auto; padding:0 20px; position:relative; }

/* header */
header { top:0; left:0; width:100%; z-index:100; height:100px; background:var(--blueGra01); border-radius:0 0 30px 0; }
header .inner { display:flex; justify-content:space-between; align-items:center; height:100%; }
header .inner .logoWrap { width:260px;}
header .inner .logoWrap a { display:block; width:100%; aspect-ratio:260 / 46;  background: url(../images/logo_white.png)center no-repeat; }
nav .menu { width:660px; display:flex; justify-content:space-between; align-items:center; }
nav .menu li a { color:rgba(255,255,255,0.5); position:relative; height:100px; line-height:100px; font-size:18px; }
nav .menu li a:after { content:''; width:18px; aspect-ratio: 1/1; display:block; position:absolute; left:50%; top:17px; transform:translate(-50%, 0);  }
nav .menu li a:hover,
nav .menu li a.active { color:rgba(255,255,255,1)}
nav .menu li a:hover:after,
nav .menu li a.active:after { background: url(../images/nav_active.png)center no-repeat; }
nav .menu .logout { border:0; background:transparent; color:rgba(255,255,255,0.5); padding:0; cursor:pointer; font-size:18px;}

/* footer */
.footer { background:#fff; text-align:center; border-top:1px solid #ccc; width:100%; }
.footer p { font-size:15px; color:var(--grey01); letter-spacing:0; font-weight:200; height:50px; line-height:50px; }