@charset "utf-8";

/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// loginPage  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.loginPage { display:flex; justify-content:space-between; height:100vh; text-align:center;  }
.loginPage:before { flex-grow:1; height:100%; content:''; background:var(--blue); }
.loginPage:after { flex-grow:1; height:100%; content:''; background:#fff; }
.loginPage .inner { flex-shrink:0; display:flex; justify-content:space-between; align-items:center; width:100%; padding: 0 !important; }
.loginPage .inner .left { width:840px; position:relative; height:100%; display:flex; justify-content:center; align-items:center; }
.loginPage .inner .left:before { content:''; position:absolute; top:0; left:0; width:100%; height:100%; background: url('../images/loginBg.png') center no-repeat; background-size:cover; 
border-radius:0 60px 60px 0; z-index:-1; }
.loginPage .inner .right { flex-grow:1; }

.loginTit { width:100%; text-align:center; }
.loginTit p { font-size:40px; font-weight:600; color:#fff; margin-top:5px; }
.loginForm { width:400px; margin:60px auto 0; }
.loginForm .inputWrap { height:60px; line-height:60px; display:flex; align-items:center; border-bottom:1px solid rgba(255,255,255,0.2); gap:5px; transition:all 0.3s;}
.loginForm .inputWrap.active { border-color:rgba(255,255,255,1);}
.loginForm .inputWrap label { width:40px; aspect-ratio: 1/1; }
.loginForm .inputWrap label[for="username"] { background:url('../images/ico_id.png') center no-repeat; background-size:contain; }
.loginForm .inputWrap label[for="password"] { background:url('../images/ico_pw.png') center no-repeat; background-size:contain; }
.loginForm .inputWrap input { background:transparent; border:0; flex-grow:1; color:#fff; font-size:20px; font-weight:300; height:60px; line-height:60px; }
.loginForm .inputWrap input::placeholder { color:rgba(255,255,255,0.5); }
.loginForm .inputWrap input:focus { outline:0; }
.loginForm .loginBtn { width:100%; height:70px; line-height:70px; font-size:24px; color:var(--blue); background:#fff; border-radius:10px; cursor:pointer;
border:0; }
.loginImg { width:395px; height:316px; background: url('../images/loginImg.png') center no-repeat; background-size:contain; transform:translateX(-60px);}
.loginForm .errorBox { height:22px; line-height:22px; margin:20px auto; }
.loginForm .errorBox .loginError { color:#fff; font-size:16px;  }

/*----------------- 로그인 휴대폰 인증  모달 ------------------*/
.verificationModal { background:#fff; padding:60px 50px; width:500px; border:3px solid var(--blue); text-align:center; border-radius: 10px; box-shadow: 0 0 15px rgba(0,0,0,0.35); position:relative; }
.verificationModal h2 { font-size:20px; color:var(--black); margin-bottom:30px; font-weight:400; line-height:30px; }
.verificationModal .verificationInput  { display:flex; justify-content:space-between; align-items:center; margin-bottom:20px; border-bottom:1px solid var(--ltgrey); }
.verificationModal .verificationInput input { height:60px; line-height:60px; border:0; font-size:20px; flex-grow:1; width:calc(100% - 100px);  }
.verificationModal .verificationInput label { height:40px; width:40px; background: url(../images/ico_mobile.png)center no-repeat; flex-shrink:0;}
.verificationModal .verificationInput .btnResend { background:#7d85a3; color:#fff; height:40px; line-height:40px; font-size:16px; border-radius:5px; cursor:pointer; width:60px;
flex-shrink:0;}
.verificationModal .btnVerification { height:70px; line-height:70px; font-size:24px; color:#fff; background:var(--blueGra); border-radius:10px; cursor:pointer; border:0; width:100%; }
.verificationModal .errorBox { height:22px; line-height:22px; margin:20px auto; }
.verificationModal .error { color:var(--red); font-size:16px;  }
.verificationModal .modalBtnWrap { display:flex; justify-content:center; align-items:center; gap:5px; }
.verificationModal .verificationInput input:focus { outline:0; }
.verificationModal .btnClose { position:absolute; top:0px; right:0px; width:40px; height:40px; background:var(--blue) url(../images/ico_delete.png)center no-repeat;
     background-size:contain; cursor:pointer; }