.ReactModal__Overlay{
    background: rgba(0, 0, 0, 0) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modalPop { width:800px; position:relative; z-index:110; overflow:hidden; box-shadow: 0 0 15px rgba(0,0,0,0.35); border-radius:10px; background:#fff; }
.modalPop .modalTitle { background:var(--blue); padding:15px 30px;  }
.modalPop .modalTitle h2 { color:#fff; font-size:20px; }
.modalPop .modalCont { background:#fff; padding:30px; }
.modalPop .modalBtnWrap { margin-top:30px; display:flex; justify-content:center; align-items:center; gap:5px; }



/*----------------- 피해자 선택 모달 ------------------*/
.btnSelect { background:var(--blue); color:#fff; padding:0 10px; height:30px; line-height:30px; font-size:14px; border-radius:5px; }

/*----------------- 파일 업로드 모달 ------------------*/
.modalPop.fileUploadModal { width:500px;}
.fileName { height:40px; font-size:15px; color:var(--navy); margin-top:10px; border-top:1px solid var(--ltgrey); padding-top:10px; }
.fileUpload input{ width:100%; height:40px; line-height:40px; font-size:15px; border:1px solid var(--ltgrey); border-radius:5px; }

/*----------------- 아이피 차단 허용 확인창 ------------------*/
.confirmPop { width:500px; text-align:center; font-size:16px; line-height:24px; }

/*----------------- 피해자 정보 입력 모달 ------------------*/
.smallPop { width:400px;  border:3px solid var(--navy); }

