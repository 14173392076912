@charset "utf-8";
/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// 서브페이지공통  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.subPage { padding:60px 0; min-height:calc(100vh - 150px); }
.subTitleWrap { display:flex; justify-content:space-between; align-items:center; margin-bottom:30px; }
.subTitleWrap.borderBtm { border-bottom:2px solid #333; }
.subTitleWrap h1.subTitle { font-size:34px; font-weight:500; color:var(--black); }
.subTitleWrap .rightBtnWrap { display:flex; gap:5px; margin-bottom:3px; }
.titleBox { display:flex; justify-content:space-between; align-items:center; margin-bottom: 15px; margin-top:50px; }
.titleBox h2.title01 { font-size:26px; font-weight:500; color:var(--black); }
.titleBox01 { display:flex; justify-content:space-between; align-items:center; margin-bottom:10px; margin-top:30px; }
h3.title02 { font-size:20px; font-weight:500; color:var(--blue); }


/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// errorPage  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.errorPage { display:flex; justify-content:center; align-items:center; min-height:calc(100vh - 160px); text-align:center; padding:60px 0; }    
.errorPage h1 { font-size:38px; font-weight:500; color:var(--black); }
.errorPage p { font-size:18px; line-height:30px; font-weight:300; color:var(--grey); margin-top:30px; display:flex; flex-direction: column; justify-content:center;}
.errorPage p:after { content:''; width:437px; height:276px; background:url(../images/errorImg.png)center no-repeat; background-size:contain; margin:30px auto 40px;  }


/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// 피해자목록, 장학생목록  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.boardListTop { display:flex; justify-content:space-between; align-items:center; margin-bottom:3px; }

/* 검색 */ 
.searchWrap .searchBox { display:flex; align-items:center; justify-content:space-between; width:300px; border:1px solid var(--ltgrey01); border-radius:5px; overflow:hidden; 
    height:46px; line-height:46px; overflow:hidden;}
.searchWrap .searchBox .inputkeyWord { border:0; flex-grow:1; padding:0 20px; line-height:44px; font-size:16px; width:calc(100% - 50px); }
.searchWrap .searchBox input::placeholder { color:var(--grey02); font-size:16px; }
.searchWrap .searchBox .searchBtn { height:48px; line-height:48px; width:48px; background:var(--ltgrey02) url(../images/ico_search_blue.png); cursor:pointer; border:0; flex-shrink:0;}

/* 검색 필터 추가 */
.searchFilterBox { background:var(--ltgrey02); padding:30px; border-radius:5px; display:flex; flex-wrap:wrap; gap:15px 50px; margin-bottom:30px; align-items:center;}
.searchFilterBox .searchFilterItem { display:flex; align-items:center; gap:10px;  }
.searchFilterBox .searchFilterItem.w100 { width:100%; }
.searchFilterBox .searchFilterItem .filterTit { font-size:16px; color:var(--grey01); }
.searchFilterBox .searchFilterItem .filterTit.w80 { width:80px; }
.searchFilterBox .searchFilterItem .filterBox { display:flex; gap:10px; align-items:center; }
.searchFilterBox .searchFilterItem .filterBox label { display:flex; gap:3px; font-size:16px; cursor:pointer; }
.searchFilterBox .searchFilterItem .inputText { border:1px solid var(--ltgrey02); height:46px; line-height:46px; padding:0 10px; font-size:16px; border-radius:5px; background:#fff; }
.searchFilterBox .searchBtn {height:46px; line-height:46px; background-color:var(--blue); cursor:pointer; border:0; padding:0 20px; border-radius:5px; margin:10px auto 0; }
.searchFilterBox .searchBtn span { color:#fff; font-size:18px; display:flex; align-items:center; gap:5px; }
.searchFilterBox .searchBtn span:before { content:''; width:20px; height:20px; background:url(../images/ico_search.png)center no-repeat; display:block; }
.searchFilterBox .alignEnd { display:flex; justify-content:flex-end; flex-grow:1; }
.searchFilterBox .alignEnd .searchBtn { margin:0; }

/*페이지 숫자*/
.boardTopLeft,
.boardTopRight { display:flex; align-items:center; gap:5px;}
.pgNumSelect select { height:46px; line-height:46px; padding:0 5px; border:1px solid var(--ltgrey01); border-radius:5px; cursor:pointer; font-size:16px; color:var(--grey01); font-size:15px;
background:#fff; }

/*목록 테이블*/
.boardTable { border-collapse:collapse; position:relative; width:100%; }
.boardTable th { background:#f4f4f4; font-size:16px; color:var(--black); border-top:1px solid #ccc; border-bottom:1px solid #ccc; padding:15px; font-weight:400; }
.boardTable td { background:#fff; font-size:16px; color:var(--grey); border-bottom:1px solid #ddd; padding:15px; text-align:center; }
.boardTable .listNum { width:80px; }
.boardBottomBtn { margin-top:5px; display:flex; align-items:center; gap:2px; }


/*페이징*/  
.pagination { display:flex; justify-content:center; align-items:center; margin-top:30px; gap:5px; }
.pagination .pageNum, 
.pagination button { display:inline-block; height:40px; line-height:40px; width:40px; text-align:center; font-size:16px; color:var(--grey); border:1px solid var(--ltgrey02); cursor:pointer; }
.pagination .pageNum { background:#fff; }
.pagination .pageNum.active { background:var(--navy); color:#fff; border:1px solid var(--navy); }
.pagination button { text-indent:-1000px; font-size:0; }
.pagination button.firstPage { background: url(../images/page_first.png)center no-repeat; }
.pagination button.prevPage { background: url(../images/page_prev.png)center no-repeat; }
.pagination button.nextPage { background: url(../images/page_next.png)center no-repeat; }
.pagination button.lastPage { background: url(../images/page_last.png)center no-repeat; }
.pagination button.disabled { cursor:default; }
.pagination button.firstPage.disabled { background: url(../images/page_first_disabled.png)center no-repeat; }
.pagination button.prevPage.disabled { background: url(../images/page_prev_disabled.png)center no-repeat; } 
.pagination button.nextPage.disabled { background: url(../images/page_next_disabled.png)center no-repeat; }
.pagination button.lastPage.disabled { background: url(../images/page_last_disabled.png)center no-repeat; } 


/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// 피해자정보입력, 장학생정보입력, 관리자정보입력  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.boardFormWrap .required { color:var(--red); padding-left:3px;}
.boardFormWrap .formBox { background:var(--ltgrey02); padding:20px; display:flex; flex-wrap:wrap; gap:5px; }
.boardFormWrap .formBox * { transition:all 0.3s; }
.boardFormWrap .formBox .inputWrap,
.boardFormWrap .formBox .selectWrap { width:30%; position:relative; flex-grow:1; background:#fff; border:1px solid var(--ltgrey01); border-radius:5px; height:60px;
display:flex; align-items:center; box-sizing:border-box;}
.boardFormWrap .formBox.economic .selectWrap { width:calc(25% - 15px/4) !important; flex-grow:unset; }
.boardFormWrap .formBox .inputWrap input,
.boardFormWrap .formBox .selectWrap select { border:0; padding:0 15px; font-size:18px; color:var(--black); width:100%; background:transparent; z-index:1; position:relative; 
    padding-top:17px; height:58px; font-weight:300; }
.boardFormWrap .formBox .textWrap { width:calc(50% - 5px/2); position:relative;  border:1px solid var(--ltgrey01); border-radius:5px; overflow:hidden; background:#fff; height:100px; }
.boardFormWrap .formBox .textWrap textarea { width:100%; height:calc(100% - 25px); border:0; padding:0 15px; background:transparent; z-index:1; position:relative; line-height:22px; 
    font-size:18px; position:absolute; top:25px; left:0; }
.boardFormWrap .formBox label { display:block; font-size:16px; color:var(--grey01); position:absolute; left:15px; top:20px; line-height:18px; z-index:0; font-weight:500; }
.boardFormWrap .formBox input::placeholder,
.boardFormWrap .formBox select::placeholder,
.boardFormWrap .formBox textarea::placeholder { opacity:0; color:var(--ltgrey01); }
.boardFormWrap .formBox .inputWrap input:focus,
.boardFormWrap .formBox .textWrap textarea:focus { outline:0;}
.boardFormWrap .formBox .familyMember,
.boardFormWrap .formBox .supportInfo,
.boardFormWrap .formBox .scholarshipHistory,
.boardFormWrap .formBox .participationHistory { width:100%; display:flex; gap:5px; flex-shrink:0; }
.boardFormWrap .formBox .active { border-color:var(--blue); }
.boardFormWrap .formBox .inputError { border-color:var(--black); } 
.boardFormWrap .formBox .active label,
.boardFormWrap .formBox .complete label { top:5px; font-size:13px; z-index:2; }
.boardFormWrap .formBox .inputError label { top:5px; font-size:13px; z-index:2; }
.boardFormWrap .formBox .active input::placeholder,
.boardFormWrap .formBox .active textarea::placeholder { opacity:1;}
.boardFormWrap .formBox .inputError input::placeholder,
.boardFormWrap .formBox .inputError textarea::placeholder { opacity:0; visibility: hidden;}
.boardFormWrap .rowDelete { width:60px; height:60px; background:#afafaf url(../images/ico_delete.png)center no-repeat; cursor:pointer; border:0; border-radius:5px; flex-shrink:0; 
    text-indent:-10000px; }
.boardFormWrap .rowAdd { display:flex; justify-content:center; align-items:center; gap:5px; margin:20px auto; background:transparent; border:0; }
.boardFormWrap .rowAdd span { font-size:18px; color:var(--black); }
.boardFormWrap .rowAdd:before { width:22px; height:22px; content:''; background:var(--blue) url(../images/ico_add.png)center no-repeat; display:block; border-radius:50%; }
.formActions { display:flex; justify-content:center; align-items:center; margin-top:60px; gap:10px;  }
.boardFormWrap .formBox.noWrap { flex-wrap:nowrap;}
.boardFormWrap .formBox .w300 { max-width:300px; }
.boardFormWrap .schoolInput > div { width:calc(20% - 10px*4/5) !important; flex-grow:initial !important; z-index:100;}
.boardFormWrap .participationHistory > div { width:calc(16.66666% - 110px/6) !important; flex-grow:initial !important; flex-shrink:0; }
.boardFormWrap .formBox.loadingForm .inputWrap span { position:absolute; color:var(--grey01); left:15px; top:5px; font-size:13px; line-height:18px; z-index:0; font-weight:500;}
.boardFormWrap .formBox.loadingForm .inputWrap div { padding:20px 15px 0 15px; font-size:18px; color:var(--black); height:58px; font-weight:300; width:100%; line-height:38px;}
.boardFormWrap .error { position:absolute; font-size:13px; color:var(--red); left:15px; bottom:0px; }
.boardFormWrap .suCheck { width:100%; display:flex; align-items:center; gap:10px; margin-bottom:10px; }
.boardFormWrap .suCheck label { position:relative; top:initial; left:0; }

/* Custom select box style for react-select */
.boardFormWrap .formBox .css-b62m3t-container{z-index:2; width:100%; border:0;}
.sv-select__control { border:0 !important; padding:0px; box-shadow:0; background: transparent !important; position:relative;  z-index:-1 !important; }
.sv-select__control--is-focused { border:0 !important; box-shadow:none !important; }
.sv-select__menu { z-index:1000 !important; position:relative; margin-top:1px !important; font-size:18px; color:var(--black); font-weight:400;  }
.sv-select__option { padding:0px; z-index:100 !important; padding-left:15px; cursor:pointer; }
.sv-select__option--is-focused { background:#fff; }
.sv-select__option--is-selected { background: #007bff; color:white; }  
.sv-select__placeholder {margin:0; font-size:0px; }
.sv-select__value-container { height:58px; padding-top:20px !important; padding-left:15px !important; font-size:18px; }
.sv-select__indicators {  position:relative;}
.boardFormWrap .css-1u9des2-indicatorSeparator { background:#fff; }

/* 피해자 선택 */
.vcSearch { position:relative; }
.vcSearchWrap { background:var(--ltgrey02); padding:20px; display:flex; flex-wrap:wrap; gap:5px 10px; justify-content:center; align-items:center; }
.vcSearchWrap .vcSearchBox { display:flex; align-items:center; justify-content:center; width:400px; gap:5px; }
.vcSearchWrap .vcSearchBox .inputkeyWord { border:1px solid var(--ltgrey01); flex-grow:1; padding:0 15px; line-height:58px; font-size:18px; background:#fff; border-radius:5px;}
.vcSearchWrap .vcSearchBox .searchBtn { height:60px; line-height:60px; background-color:var(--blue); cursor:pointer; border:0; padding:0 20px; border-radius:5px;  }
.vcSearchWrap .vcSearchBox .searchBtn span { color:#fff; font-size:18px; display:flex; align-items:center; gap:5px; }
.vcSearchWrap .vcSearchBox .searchBtn span:before { content:''; width:20px; height:20px; background:url(../images/ico_search.png)center no-repeat; display:block; }

  
/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// 피해자 정보상세, 장학생 정보상세  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.scInfoWrap .scInfoBox { display:flex; width:100%; justify-content:space-between; margin-top:-1px; }
.scInfoWrap .scInfoBox .scInfoTitle { width:140px; flex-shrink:0; display:flex; align-items:center; justify-content:center; background: var(--ltgrey02); border:1px solid #ccc; border-right:0;}
.scInfoWrap .scInfoBox .scInfoTitle span { font-size:18px; color:var(--blue); font-weight:500; }
.scInfoWrap .scInfoBox .scInfoContent { flex-grow:1;}


/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// ip 접속 허용  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.ipPermissionCnts { display:flex; flex-wrap:wrap; gap:50px; }
.ipPermissionCnts .newIp { width:100%; }
.ipPermissionCnts .ipList { width:100%; }
.ipPermissionCnts .ipInputWrap { display:flex; align-items:center; gap:10px; margin-bottom:10px; padding:30px 20px; justify-content:center;  background:var(--ltgrey02); flex-wrap:wrap; }
.ipPermissionCnts .ipInputWrap .ipInputBox { display:flex; gap:2px; align-items:center; justify-content:center; width:100%; margin-bottom:5px; }
.ipPermissionCnts .ipInputWrap .ipInputBox input { flex-grow:1; height:46px; line-height:46px; border:1px solid var(--ltgrey01); border-radius:5px; max-width:100px; padding:0 10px; 
    font-size:16px; color:var(--black); }


/* -----------------------------------------------------------------------------------------------
///////////////////////////////////////////// 통계  //////////////////////////////////////
--------------------------------------------------------------------------------------------------*/
.statisSearchWrap { background:var(--ltgrey02); padding:30px; border-radius:5px; }
.statisSearchWrap .searchType { display:flex; align-items:center; }
.statisSearchWrap .searchType + .searchType { margin-top:15px; }
.statisSearchWrap .searchType .tit { width:100px; font-size:18px; color:var(--grey01);  }
.statisSearchWrap .searchType .cnts { display:flex; gap:5px; font-size:18px; align-items:center; }
.statisSearchWrap .searchType .cnts .radioGroup { display:flex; gap:15px; align-items:center; }
.statisSearchWrap .searchType .cnts .radioGroup span { padding-right:2px; }
.statisSearchWrap .searchType .cnts .radioGroup input { margin-right:5px;}
.statisSearchWrap .searchType .searchBtn { height:46px; line-height:46px; background-color:var(--blue); cursor:pointer; border:0; padding:0 20px; border-radius:5px; }
.statisSearchWrap .searchType .searchBtn span { color:#fff; font-size:16px; display:flex; align-items:center; gap:5px; }
.statisSearchWrap .searchType .searchBtn span:before { content:''; width:20px; height:20px; background:url(../images/ico_search.png)center no-repeat; display:block; }
.searchType .dateButtons { display:flex; gap:2px;}
.searchType .dateButton { height:46px; line-height:46px; width:60px; border:1px solid var(--ltgrey01); border-radius:5px; text-align:center; background:#fff; font-size:15px; }
.searchType .dateButton.selected { border-color:var(--blue); color:var(--blue); }
.react-datepicker__input-container input {  border:1px solid var(--ltgrey02); height:46px; line-height:46px; padding:0 10px; width:180px; font-size:16px; border-radius:5px; 
    background:url(../images/ico_calendar.png)left 10px center no-repeat; background-color:#fff; padding-left:35px; }
.react-datepicker__input-container input:focus { outline:0; }
.react-datepicker__day-name, 
.react-datepicker__day, .react-datepicker__time-name { width:24px; line-height:24px; font-size:13px; }
.react-datepicker__current-month, 
.react-datepicker-time__header, 
.react-datepicker-year-header { font-size:14px; line-height:24px;}
.react-datepicker__tab-loop { position:absolute; }
.statisticsPage .textBox { border:1px solid var(--ltgrey01); border-radius:5px; padding:20px; margin-top:20px; text-align:center; font-size:18px; }








